import React from "react";
import "../CSS/RubiksCube.css";

const RubiksCube = () => {
  return (
    <div className="media-content" style={{ "--largest-width": "800px" }}>
      <img
        alt="Rubik's Cube"
        width="732"
        height="558"
        data-id="4162002"
        data-animated-url="https://cdn.dribbble.com/users/21140/screenshots/4162002/media/b25dcf148011a7cc2903ee62613c9b76.gif"
        skip_resize="true"
        srcSet="
          https://cdn.dribbble.com/users/21140/screenshots/4162002/media/b25dcf148011a7cc2903ee62613c9b76.gif 320w,
          https://cdn.dribbble.com/users/21140/screenshots/4162002/media/b25dcf148011a7cc2903ee62613c9b76.gif 400w,
          https://cdn.dribbble.com/users/21140/screenshots/4162002/media/b25dcf148011a7cc2903ee62613c9b76.gif 450w,
          https://cdn.dribbble.com/users/21140/screenshots/4162002/media/b25dcf148011a7cc2903ee62613c9b76.gif 640w,
          https://cdn.dribbble.com/users/21140/screenshots/4162002/media/b25dcf148011a7cc2903ee62613c9b76.gif 700w,
          https://cdn.dribbble.com/users/21140/screenshots/4162002/media/b25dcf148011a7cc2903ee62613c9b76.gif 800w,
          https://cdn.dribbble.com/users/21140/screenshots/4162002/media/b25dcf148011a7cc2903ee62613c9b76.gif 768w
        "
        sizes="(max-width: 919px) 100vw, max(768px, 98vh)"
        src="https://cdn.dribbble.com/users/21140/screenshots/4162002/media/b25dcf148011a7cc2903ee62613c9b76.gif"
      />
    </div>
  );
};

export default RubiksCube;
