import React, { useState, useEffect, useCallback, memo } from "react";
import Spline from "@splinetool/react-spline";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components/Layout";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  IconButton,
  useColorMode,
  useColorModeValue,
  Text,
  Flex,
  SlideFade,
  Button,
} from "@chakra-ui/react";
import { FaSun, FaMoon } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import "../CSS/LandingPage.css";
import RubiksCube from "../components/RubiksCube";

// Import icons
import diamondIcon from "../Icons/diamond.png";
import engineIcon from "../Icons/engine.png";
import stackIcon from "../Icons/stack.png";
import chartIcon from "../Icons/Tableau.png";
import graphIcon from "../Icons/PowerBI.png";
import mapIcon from "../Icons/Shiny.png";
import reportIcon from "../Icons/datawrapper.png";
import aiIcon1 from "../Icons/ChatGPT.png";
import aiIcon2 from "../Icons/Gemini.png";
import aiIcon3 from "../Icons/Claud.png";
import aiIcon4 from "../Icons/Perplexity.png";
import receiveIcon1 from "../Icons/CSV.png";
import receiveIcon2 from "../Icons/API.png";
import receiveIcon3 from "../Icons/powerautomate.png";

// Constants
const MAIN_ICONS = [
  { src: diamondIcon, label: "Visualize", showArrow: true },
  { src: engineIcon, label: "AI Tools", showArrow: true },
  { src: stackIcon, label: "Receive", showArrow: true },
];

const ADDITIONAL_ICONS = {
  Visualize: [
    { src: chartIcon, label: "Tableau", delay: 0.1, showArrow: true },
    { src: graphIcon, label: "Power BI", delay: 0.2, showArrow: true },
    { src: mapIcon, label: "Shiny", delay: 0.3, showArrow: true },
    { src: reportIcon, label: "Datawrapper", delay: 0.4, showArrow: true },
  ],
  "AI Tools": [
    { src: aiIcon1, label: "Chat GPT", delay: 0.1, showArrow: true },
    { src: aiIcon2, label: "Gemini", delay: 0.2, showArrow: true },
    { src: aiIcon3, label: "Claude", delay: 0.3, showArrow: true },
    { src: aiIcon4, label: "Perplexity", delay: 0.4, showArrow: true },
  ],
  Receive: [
    { src: receiveIcon3, label: "Power Automate", delay: 0.1, showArrow: true },
    { src: receiveIcon1, label: "CSV", delay: 0.2, showArrow: true },
    { src: receiveIcon2, label: "API", delay: 0.3, showArrow: true },
  ],
};

const EXPLORE_LINKS = {
  Tableau: "/tableau?mainIcon=Visualize",
  Shiny: "/Shineyapp?mainIcon=Visualize",
  Datawrapper: "/datawrapper?mainIcon=Visualize",
  "Chat GPT": "/AIChat?model=openai&mainIcon=AI%20Tools",
  Claude: "/AIChat?model=claude&mainIcon=AI%20Tools",
  Gemini: "/AIChat?model=gemini&mainIcon=AI%20Tools",
  Perplexity: "/AIChat?model=Perplexity&mainIcon=AI%20Tools",
  CSV: "/AnalyticsBuilder?mainIcon=Receive",
  API: "/APIBuilder?mainIcon=Receive",
  "Power BI": "/PowerBI?mainIcon=Visualize",
  "Power Automate": "/PowerAutomate?mainIcon=Receive",
};

// Memoized IconTile component
const IconTile = memo(({ icon, isSelected, onClick }) => (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.6, ease: "easeOut" }}
    whileHover={{ scale: 1.05 }}
    className={`icon-tile ${isSelected ? "selected" : ""}`}
    onClick={() => onClick(icon)}
    role="button"
    tabIndex={0}
    onKeyPress={(e) => {
      if (e.key === "Enter" || e.key === " ") {
        onClick(icon);
      }
    }}
  >
    <img src={icon.src} alt={icon.label} className="icon" draggable="false" />
    <Flex align="center" gap="8px">
      <Text className="icon-label">{icon.label}</Text>
      {icon.showArrow && (
        <ArrowForwardIcon
          w={4}
          h={4}
          transform="rotate(315deg)"
          color="White"
        />
      )}
    </Flex>
  </motion.div>
));

// Memoized AdditionalIcon component
const AdditionalIcon = memo(
  ({ icon, isSelected, isHovered, onClick, onHover }) => (
    <motion.div
      key={icon.src}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{
        delay: icon.delay,
        duration: 0.4,
        ease: "easeOut",
      }}
    >
      <div
        className={`additional-icon-tile ${isSelected ? "selected" : ""} ${
          isHovered ? "hovered" : ""
        }`}
        onClick={() => onClick(icon)}
        onMouseEnter={() => onHover(icon.src)}
        onMouseLeave={() => onHover(null)}
      >
        <img src={icon.src} alt={icon.label} className="additional-icon" />
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <span className="icon-label">{icon.label}</span>
          {icon.showArrow && (
            <ArrowForwardIcon
              w={4}
              h={4}
              transform="rotate(315deg)"
              color="White"
            />
          )}
        </div>
      </div>
    </motion.div>
  )
);

// Arrow Structure Component
const ArrowStructure = ({ isVertical }) => (
  <div className={`arrow-structure ${isVertical ? "vertical" : ""}`}>
    <div className="horizontal-line">
      <div className="arrow-branch arrow-visualize" />
      <div className="arrow-branch arrow-ai-tools" />
      <div className="arrow-branch arrow-receive" />
    </div>
  </div>
);

const LandingPage = () => {
  const navigate = useNavigate();
  //const { toggleColorMode } = useColorMode();
  const [selectedMainIcon, setSelectedMainIcon] = useState(null);
  const [selectedAdditionalIcon, setSelectedAdditionalIcon] = useState(null);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [showAdditionalIcons, setShowAdditionalIcons] = useState(false);
  const [displayedText, setDisplayedText] = useState("");
  const [isVertical, setIsVertical] = useState(false);

  const colorMode = useColorModeValue("light", "dark");

  useEffect(() => {
    const handleResize = () => {
      setIsVertical(window.innerWidth <= 480);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMainIconClick = useCallback((icon) => {
    setSelectedMainIcon((prev) => (prev?.src === icon.src ? null : icon));
    setShowAdditionalIcons((prev) => prev?.src !== icon.src);
    setSelectedAdditionalIcon(null);
    setDisplayedText("");
  }, []);

  const handleAdditionalIconClick = useCallback(
    (icon) => {
      setSelectedAdditionalIcon((prev) =>
        prev?.src === icon.src ? null : icon
      );
      // Navigate directly when clicking on additional icon
      if (EXPLORE_LINKS[icon.label]) {
        const mainIconLabel = selectedMainIcon?.label.replace(" ", "%20");
        navigate(`${EXPLORE_LINKS[icon.label]}&mainIcon=${mainIconLabel}`);
      }
    },
    [navigate, selectedMainIcon]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const mainIconLabel = searchParams.get("mainIcon");
    if (mainIconLabel) {
      const mainIcon = MAIN_ICONS.find(
        (icon) => icon.label === mainIconLabel.replace("%20", " ")
      );
      if (mainIcon) {
        setSelectedMainIcon(mainIcon);
        setShowAdditionalIcons(true);
      }
    }
  }, []);

  // const handleExploreClick = useCallback(() => {
  //   if (
  //     selectedAdditionalIcon?.label &&
  //     EXPLORE_LINKS[selectedAdditionalIcon.label]
  //   ) {
  //     navigate(EXPLORE_LINKS[selectedAdditionalIcon.label]);
  //   }
  // }, [selectedAdditionalIcon, navigate]);

  // useEffect(() => {
  //   if (!selectedAdditionalIcon) return;

  //   const typewriterText = `Information about ${selectedMainIcon?.label}. This is a detailed description for ${selectedAdditionalIcon?.label}.`;
  //   setDisplayedText("");
  //   let index = 0;

  //   const interval = setInterval(() => {
  //     if (index < typewriterText.length) {
  //       setDisplayedText(typewriterText.substring(0, index + 1));
  //       index++;
  //     } else {
  //       clearInterval(interval);
  //     }
  //   }, TYPEWRITER_DELAY);

  //   return () => clearInterval(interval);
  // }, [selectedAdditionalIcon, selectedMainIcon]);

  return (
    <Layout>
      <AnimatePresence mode="wait">
        <div className={`landing-page ${colorMode}-mode`}>
          {/* <IconButton
            className="theme-toggle"
            onClick={toggleColorMode}
            icon={colorMode === "light" ? <FaSun /> : <FaMoon />}
            aria-label="Toggle Dark Mode"
          /> */}

          <div className="spline-container">
            <RubiksCube />
            <ArrowStructure isVertical={isVertical} />

            <div
              className={`icon-tiles-container ${isVertical ? "vertical" : ""}`}
            >
              {MAIN_ICONS.map((icon, index) => (
                <IconTile
                  key={index}
                  icon={icon}
                  isSelected={selectedMainIcon?.src === icon.src}
                  onClick={handleMainIconClick}
                />
              ))}
            </div>
          </div>

          <AnimatePresence mode="wait">
            {selectedMainIcon && (
              <motion.div
                key={selectedMainIcon.src}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.4 }}
                className="selected-content"
              >
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2, duration: 0.4 }}
                  className="selected-icon-container"
                >
                  <img
                    src={selectedMainIcon.src}
                    alt="Selected Main Icon"
                    className="selected-main-icon"
                  />
                  <Text className="icon-label">{selectedMainIcon.label}</Text>
                </motion.div>

                <AnimatePresence>
                  {selectedAdditionalIcon && (
                    <motion.div
                      key={selectedAdditionalIcon.src}
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 20 }}
                      transition={{ delay: 0.3, duration: 0.4 }}
                      className="selected-additional-icon"
                    >
                      <img
                        src={selectedAdditionalIcon.src}
                        alt="Selected Additional Icon"
                        className="selected-icon"
                      />
                      <Text className="icon-label">
                        {selectedAdditionalIcon.label}
                      </Text>
                    </motion.div>
                  )}
                </AnimatePresence>

                {showAdditionalIcons && (
                  <motion.div
                    key={selectedMainIcon.label}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    className="additional-icons-container"
                  >
                    {ADDITIONAL_ICONS[selectedMainIcon.label]?.map(
                      (icon, index) => (
                        <AdditionalIcon
                          key={icon.src}
                          icon={icon}
                          isSelected={selectedAdditionalIcon?.src === icon.src}
                          isHovered={hoveredIcon === icon.src}
                          onClick={handleAdditionalIconClick}
                          onHover={setHoveredIcon}
                        />
                      )
                    )}
                  </motion.div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </AnimatePresence>
    </Layout>
  );
};

export default LandingPage;
